<template>
  <div class="row"><div class="columns large-12"><h1>Container bestellen</h1></div></div>
 <img src="@/assets/images/containerdienst.jpg" style="width: 100%; display: block; margin-bottom: 24px;" />
  <div class="row">
    <div class="columns large-12 medium-12 small-12"><p>ugiat parum usus legentis facilisis quis. Esse duis claritas dolore elit etiam. Nam ii consuetudium quod saepius processus. Decima et
      legentis et in vel. Eros luptatum processus commodo elit amet. Qui eodem est delenit claram seacula.</p><p>
      Velit qui imperdiet soluta augue eum. Humanitatis claritas congue dolor notare iis. In consequat dolore nobis gothica nibh. Nunc option
      dignissim quam videntur et. Dolor tempor dolor qui aliquip esse.</p>

    <div class="zipEntry">
      <div class="row">
        <div class="columns xlarge-9 large-8 medium-6 small-12"><input type="text" v-model="zip" placeholder="Postleitzahl der Lieferadresse" @keyup.enter="checkAvailability"/></div>
        <div class="columns xlarge-3 large-4 medium-6 small-12"><button class="btn" @click="checkAvailability">Verfügbarkeit prüfen</button></div>
      </div>
    </div>

    </div>
  </div>





</template>
<script>
import axios from "axios";
export default {
  name: "Reorder",
  data: function () {
    return {
      zip: "",
      allowedZips: ["93138", "93092", "93093", "93182", "93183", "93096", "93073", "93152", "93083", "93080", "93186", "93188", "93128", "93161", "93105", "93173", "93195", "93197", "93055", "93051", "93049", "93047", "93057", "93053", "93059", "93077", "93087", "93177", "93089", "93090", "93176", "93170", "93179", "93164", "93180", "93095", "93155", "93098", "93099", "93101", "93102", "93104", "84069", "93107", "93109", "93086", "93133", "93345", "93346", "93309", "84085", "93142", "93351", "93342", "93158", "93356", "93192"],
    }
  },
  methods: {
    checkAvailability() {
      if (!this.allowedZips.includes(this.zip)) {
        this.zip = "";
        alert("Leider ist diese Postleitzahl nicht verfügbar.");
      } else {

        this.$store.state.order.zip = this.zip;


      this.$router.push("/type");
      }
    }
  },

};
</script>
