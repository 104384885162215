import { createStore } from 'vuex'
import axios from 'axios'
import router from '@/router'
import { useToast } from 'vue-toastification'
import environment from '../auth/environment';
import i18n from '../lang'

const toast = useToast()
export default createStore({
  state: {
    stations: [],
    order:{
      zip: '',
    },
    prices:{
      transport: 0,
      rent: 0,
      pricePerTon: 0,
      rentMonthly: 0,
      stellgenehmigung: ''
    },
    customer: {},
    progress: 0,
    activeStation: false,
    version:environment.version,
    apiUrl: environment.apiUrl,
    loggedInState: false,
    user: {
      crm_customer_id: 0,
      id: 0,
      name: '',
      scope: '',
      _isEmployee: 0,
    },
    timestamp: false,
    scope: { scope: '', write: false },
    acl: '',
    publishNewVersion: false,
    feedback: {

    },
    toasts: [],
    currentProgress: 1,
    currentApplication: null,
    currentApplicationClosedState: true,
    maxDateValue: '9999-12-31',
  },
  getters: {
    getToasts: state => {
      return state.toasts
    },
    getCurrentApplication: state => {
      return state.currentApplication;
    },
    getCurrentApplicationClosedState: state => {
      return state.currentApplicationClosedState;
    },
    getApiUrl: state => {
      return state.apiUrl
    },
    checkLogin: state => {
      return state.loggedInState// use with this.$store.getters.checkLogin
    },
    getUser: state => {
      return state.user
    },
    getToken() {
      return localStorage.getItem("sketchManagerToken");
    },
    getApiAuth () {
     return {"crmAuth":localStorage.getItem("sketchManagerToken")};
    },
    getCurrentProgress: state => {
      return state.currentProgress
    }
  },
  mutations: {
    handleApiError(state, customMessage) {

      if (customMessage && customMessage.response && customMessage.response.data && customMessage.response.data.todo) {
        if (customMessage.response.data.todo.method === 'logout') {
          this.commit("logout");
        }
        if(customMessage.response.data.todo.content === 'login_incorrect') {
          toast.error("Login fehlgeschlagen");
        }
        else {
          toast.error(customMessage.response.data.todo.content);
        }
      }
      else {
        toast.error("Es ist ein Fehler aufgetreten. 1");
      }

    },
    setCurrentApplication(state, payload) {
      state.currentApplication = payload;
    },
    setClosedState(state, payload) {
      state.currentApplicationClosedState = payload;
    },
    changeLoggedIn (state, todo) { // or changeLoggedIn(state)
      const checkSwitch = state.loggedInState
      if (todo === 'toNull') {
        state.loggedInState = null
        localStorage.removeItem('sketchManagerToken')
      } else if (todo === true) {
        state.loggedInState = true
      } else {
        state.loggedInState = !state.loggedInState // use with this.$store.commit("changeLoggedIn")
      }
      if (checkSwitch !== state.loggedInState && state.loggedInState === true) {
        // router.push("/");
      }
      if (checkSwitch !== state.loggedInState && state.loggedInState === false) {
        router.push('/login')
      }
    },
    setToken (state, hash) { // or changeLoggedIn(state)
      state.token = !state.loggedInState // use with this.$store.commit("changeLoggedIn")
      localStorage.setItem('sketchManagerToken', hash)
    },
    setAcl (state, acl) { // or changeLoggedIn(state)
      state.acl = acl // use with this.$store.commit("changeLoggedIn")
    },
    setScope (state, acl) { // or changeLoggedIn(state)
      state.scope = acl // use with this.$store.commit("changeLoggedIn")
    },
    setUser (state, user) { // or changeLoggedIn(state)
      state.user = user // use with this.$store.commit("changeLoggedIn")
    },
    logout(state) {

      state.loggedInState = null
      state.scope = { scope: null, write: false, loggedOut: true }
      state.acl = null
      state.user = null
      state.currentProgress = 1
      state.currentApplication = null
      state.user = null
      localStorage.removeItem('sketchManagerToken')
      router.push('/');
    },
    forceSWupdate (state) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            registration.update()
          }
        })
      }
      if (localStorage.getItem('latest_cache_version') !== null && localStorage.getItem('latest_cache_version') !== 'null') {
        state.publishNewVersion = true
      } else {
        state.publishNewVersion = false
      }
    },


    removeActivation(state) {
    state.user.requireActivation = false;
      },

  },
  actions: {
    handleApiResponse({commit}, shortResponse) {
      if (!shortResponse.data.todo) {
        return false;
      }

      const toast = useToast();
      switch (shortResponse.data.todo.method) {
        case "showMessage":

          let testMessage = shortResponse.data.todo.content;
          if (!i18n.de.errors[testMessage]) {
            toast.error(testMessage);
          }
          else {
          toast.error(i18n.de.errors[testMessage]);
          }

          break;
        case "logout":
          toast.error(shortResponse.data.todo.content);
          commit("logout");
          break;
        default:
          alert("default in handleApiResponse");
      }
    },
    handleApiError({commit}, customMessage) {


      if (customMessage && customMessage.response && customMessage.response.data && customMessage.response.data.todo) {
        if (customMessage.response.data.todo.method === 'logout') {
          commit("logout");
        }
        if(customMessage.response.data.todo.content === 'login_incorrect') {
          toast.error("Login fehlgeschlagen");
        }
        else {
        toast.error(customMessage.response.data.todo.content);
        }
      }
      else {
        toast.error("Es ist ein Fehler aufgetreten. 2 ");
      }

    },
    setToastsDone() {
      toast.success("Daten wurden gespeichert!");
    },
    handleCoupon({state}){
      if(!state.coupon) {
         // router.push('/')
        // Toast message alert, that the coupon is not valid
        const toast = useToast();
        toast.error(i18n.de.activate.error);
      }
    },
    login ({ commit, getters }, payload) {
      if (!navigator.onLine) {
        commit('showErrorMessage', 'Diese Funktion ist offline nicht verfügbar')
      } else {
        commit('changeLoggedIn', 'toNull')
        axios.post(getters.getApiUrl + 'login', payload).then(function (response) {
          const shortResponse = response.data.result
          if (shortResponse.success === true) {
            commit('setToken', shortResponse.hash)

            commit('setUser', shortResponse.user)
            commit('setScope', shortResponse.scope)
            commit('changeLoggedIn', true)
            if (shortResponse.scope === 'customer' || shortResponse.scope === 'joomla-superuser') {
              router.push({name: 'dashboard'})
              toast.success("Willkommen im Bewerbungsportal!");
            }
          } else {
            commit('handleApiResponse', response)
          }
        }).catch(function (error) {


          commit('handleApiError', error)
        })
      }
    },
    checkToken ({ commit, getters }) {
      if (navigator.isOnline === undefined || navigator.isOnline) {
        const token = getters.getToken
        if (token) {
          axios.post(getters.getApiUrl + 'checkToken', { token: token }).then(function (response) {
            const shortResponse = response.data.result
            if (shortResponse.success === true) {
              commit('setToken', shortResponse.hash)

              commit('setUser', shortResponse.user)
              commit('setScope', shortResponse.user.scope)
              commit('changeLoggedIn', true)
              if (shortResponse.user.scope === 'customer' || shortResponse.user.scope === 'joomla-superuser') {
                router.push({name: 'dashboard'})
                toast.success('Willkommen im Bewerbungsportal!')
              }
            } else {
              commit('handleApiResponse', response)
            }
          }).catch(function (error) {
            commit('handleApiError', error)
          })
        }
      }
    },
    logout ({ commit }) {
      commit('logout')
    }
  },
  modules: {
  }
})
