<template>
   <footer>
 <div class="phoneHint">
   <span><i class="fa-solid fa-phone-volume"></i> Benötigen Sie telefonische Beratung?</span>
 </div>
   </footer>
</template>
<script>
export default {
  name: "footerView",
  props: {
  }
}
</script>
