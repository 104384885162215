<template>
  <main>
    <div class="selectNeuerAuftrag">
      <div v-if="showThankYou">
        <div class="row">
          <div class="columns large-12 medium-12 small-12">
            <div class="singleWrapper">
              <h2>Vielen Dank für Ihre Bestellung</h2>

                <p>Wir haben Ihre Bestellung erhalten und werden uns in Kürze bei Ihnen melden.</p>
                <p>Weitere Informationen erhalten Sie per E-Mail.</p>

            </div>
          </div>
        </div>
      </div>
      <div v-else-if="errorMessage !==''">
        <div class="row">
          <div class="columns large-push-3 large-6 medium-12 small-12">
            <div class="singleWrapper red">
              <h2>Es ist ein Fehler aufgetreten</h2>
              <p>{{ errorMessage }}</p>
              <!-- keep saved data and get back to the best possible step -->
            </div>
          </div>
        </div>
      </div>
      <div v-else class="newWasteWrapper" :class="step">
        <div class="row">
          <div class="columns large-12 medium-12 small-12">
            <div class="zipEntry">
              <div class="row">
                <div class="columns xlarge-9 large-8 medium-6 small-12"><input type="text"
                                                                               v-model="$store.state.order.zip" readonly
                                                                               placeholder="Postleitzahl der Lieferadresse"/>
                </div>
                <div class="columns xlarge-3 large-4 medium-6 small-12">
                  <button class="btn" @click="$router.push('/')">Postleitzahl ändern</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" style="width: 100%; margin: 0 auto;">
          <div class="columns large-6 medium-12 small-12">
            <div class="singleWrapper">
              <template v-if="step!=='waste'">
                <div class="overlay"></div>
                <button type="button" class="btn btn-right changeBtn" @click="step='waste'">Ändern</button>
              </template>
              <div class="filterBar">
                <div class="groupLabelInput withoutLabel">
                  <input type="text" v-model="filter.searchWasteType"
                         placeholder="Abfallart suchen..">
                </div>
              </div>
              <h2>Abfallart</h2>
              <p v-if="step==='waste'">Wählen Sie eine Abfallart aus</p>
              <template v-for="(category, catid) in categories">
                <div class="konfigCategoryWrap"
                     v-if="((filter.filterActive && checkItems(category)) || !filter.filterActive ) && category.category.title">
                  <div class="binvollSelect typeCategory" :class="(save.category_id === catid) ? 'active': ''"
                       @click="selectItem(catid, 'category_id')"
                  >
                    <div class="selectImage">
                      <img :src="'https://kundenportal.meindl-entsorgung.de/'+category.category.params.image"
                           v-if="category.category.params && category.category.params.image"/>
                    </div>
                    <div class="selectTitle">{{ category.category.title }}</div>
                  </div>
                </div>
                <div class="konfigWasteWrap" v-for="waste in category.items" >
                  <template v-if="save.category_id === catid && (filter.searchWasteType.length <= 0 || (waste && waste.filterMatch))">
                  <div class="binvollSelect typeWaste" :class="(save.waste_id === waste.id) ? 'active': ''"
                       @click="selectItem(waste.id, 'waste_id', waste); save.container_id = '';showWaste = waste;">
                    <div class="selectImage">
                      <img :src="'https://kundenportal.meindl-entsorgung.de/'+waste.icon" v-if="waste.icon"
                           alt="">
                      <img src="/templates/binvoll/images/Placeholder_abfallart.png" v-else
                           alt=""/>
                    </div>
                    <div class="selectTitle">{{ waste.waste_name }}</div>
                    <span v-if="waste.description" @click="showSortenInfoDirect(waste)" class="showWasteInfo"><i
                      class="fa-regular fa-circle-info"></i></span>
                  </div></template>
                </div>
              </template>
            </div>
          </div>
          <div class="columns large-6 medium-12 small-12">
            <div class="singleWrapper" v-if="showWaste">
              <template v-if="step!=='waste'">
                <div class="overlay"></div>
                <button type="button" class="btn btn-right changeBtn" @click="step='waste'">Ändern</button>
              </template>
              <div class="searchContainerWrap" v-if="su">
                <input type="text" v-model="filter.searchContainer" placeholder="Container suchen.. "/>
              </div>
              <h2>Container</h2>
              <p v-if="step==='waste'">Wählen Sie einen Container aus</p>
              <div class="userContainersWrapper" v-if="showWaste.container_mapping">
                <div class="konfigContainerWrap" v-for="(container, cid) in showWaste.container_mapping"
                     v-if="save.waste_id === showWaste.id">
                  <template
                    v-if="container && container.title && (!su || (su && container.title.toLowerCase().indexOf(filter.searchContainer.toLowerCase()) >= 0 ))">
                    <div class="binvollSelect typeContainer"
                         :class="(save.container_id === container.id) ? 'active': ''"
                         @click="selectItem(container.id, 'container_id', container)">
                      <div class="selectImage">
                        <img :src="'https://kundenportal.meindl-entsorgung.de/'+container.image" v-if="container.image"
                             alt="">
                      </div>
                      <div class="selectTitle">{{ container.title }}</div>
                      <span @click="showSortenInfoDirect(container)" class="showWasteInfo" v-if="container.description"><i
                        class="fa-regular fa-circle-info"></i></span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="buttonRow">
                <button v-if="save.waste_id != '' && save.container_id != ''"
                        class="nextSelectContainer btn" type="button"
                        @click="step = 'location'; setAdditionals();">Bestätigen
                </button>
                <button v-else class="nextSelectContainer btn " type="button" disabled>Bestätigen
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" v-if="step === 'location'  || step ==='ansprech'">
          <div class="columns large-6 medium-12 small-12">
            <div class="singleWrapper">
              <h2>Angebot</h2>
              <div class="box">
                <p><strong
                  v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_name && fullDisplay.container_id && fullDisplay.container_id.title">Container
                  für {{ fullDisplay.waste_id.waste_name }}</strong><br>
                  in
                  <template v-if="$store && $store.state && $store.state.order && $store.state.order.zip">
                    {{ $store.state.order.zip }}
                  </template>
                  {{ this.getSelectedCityByZip }}<br>
                  {{ fullDisplay.container_id.title }}
                </p>
                <table>
                  <tbody>

                  <tr>
                    <td>Transportkosten (Container Liefern und wieder abholen)</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.transport) }}</td>
                  </tr>
                  <tr>
                    <td>Miete monatlich (14-Tage Mietfrei)</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.rentMonthly) }}</td>
                  </tr>
                  <tr>
                    <td>Entsorgungskosten pro Tonne*</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.pricePerTon) }}</td>
                  </tr>
                  <tr>
                    <td>Bezahlart</td>
                    <td style="text-align: right;">Kauf auf Rechnung</td>
                  </tr>
                  </tbody>
                </table>
                <template v-if="fullDisplay.waste_id.additionals.length > 0">
                  <h4 style="margin-bottom: 12px;">
                    Empfohlene Zusatzartikel</h4>
                  <template v-for="add in fullDisplay.waste_id.additionals">
                    <div class="row">
                      <div class="columns xlarge-5 large-7 medium-12 small-12">
                        <div class="checkbox"><input v-model="save.additionals[add.id].id" type="checkbox"
                                                     :id="'additional_'+add.id"><label
                          :for="'additional_'+add.id">{{ add.container_name }}</label></div>
                      </div>
                      <div class="columns xlarge-4 large-5 medium-12 small-12"><input type="number" min="0"
                                                                                      v-if="save.additionals[add.id].id"
                                                                                      placeholder="Anzahl / Menge"
                                                                                      step="1" style="margin-top: 17px;"
                                                                                      v-model="save.additionals[add.id].amount"/>
                      </div>
                      <div style="text-align: right;padding-top: 24px;"
                           class="columns xlarge-3 large-12 medium-12 small-12">
                        <template
                          v-if="add.add_price && save.additionals[add.id].amount && save.additionals[add.id].amount > 0 && save.additionals[add.id].id">
                          {{ priceEuro(add.add_price) }} x {{ save.additionals[add.id].amount }} =
                          {{ priceEuro(add.add_price * save.additionals[add.id].amount) }}
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
                <p v-if="save.newLocation.stell">Zzgl. Gebühren für die Beantragung der öffentlichen Stellfläche
                 </p>
                <p>Die Gesamtkosten werden Ihnen nach Abzug des Containers in Rechnung gestellt.<br>
                  Preise vorbehaltlich Sortenänderung nach Sortenprüfung.<br>
                  Alle Preise inkl. gesetzlicher MwSt.<br><br>* Bitte beachten Sie die Mindestlastvorgaben von
                  {{ fullDisplay.waste_id.waste_amount }}kg.
                </p>
                <button class="btn" @click="step='ansprech'">Akzeptieren und weiter</button>
              </div>
            </div>
          </div>
          <div class="columns large-6 medium-12 small-12" v-if="step ==='ansprech'">
            <div class="singleWrapper">
              <h2>Ihre Daten</h2>
              <div class="box" style="margin-bottom: 24px;" v-if="!isLoggedIn">
                <div class="row">
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <input type="text" style="margin-top: 3px;" placeholder="E-Mail-Adresse" :readonly="(emailIsRegisteredComplete)" v-model="save.newAnsprech.email" @keypress.enter="checkValidEmail" />



                  </div>
                  <div class="columns xlarge-2 large-4 medium-4 small-12"><button type="button" v-if="emailIsRegisteredComplete" class="btn btn-danger" @click="emailIsRegisteredComplete = false; save.newAnsprech.email = ''; emailIsRegistered=false;">x</button></div>
                  <div class="columns xlarge-4 large-8 medium-8 small-12">

                    <button type="button" class="btn" @click="checkValidEmail">Weiter</button>
                  </div>
                </div>
              </div>
              <div class="box" v-if="!emailIsRegistered && emailIsRegisteredComplete">
                <div class="row">
                  <div class="columns large-6 medium-12 small-12">
                    <h4>Lieferadresse:</h4>
                    <input type="text" v-model="save.newLocation.company" placeholder="Firma"/>
                    <input type="text" v-model="save.newLocation.street" placeholder="Straße *"/>
                    <input type="text" v-model="save.newLocation.zip" placeholder="Postleitzahl" readonly/>
                    <input type="text" v-model="save.newLocation.city" readonly placeholder="Ort *"/>
                    <template v-if="needsExtraAddress">
                      <h4>Rechnungsadresse</h4>
                      <input type="text" v-model="save.newRechnung.company" placeholder="Firma"/>
                      <input type="text" v-model="save.newRechnung.firstname" placeholder="Vorname"/>
                      <input type="text" v-model="save.newRechnung.lastname" placeholder="Nachname"/>
                      <input type="text" v-model="save.newRechnung.street" placeholder="Straße"/>
                      <input type="text" v-model="save.newRechnung.zip" placeholder="Postleitzahl"  />
                      <input type="text" v-model="save.newRechnung.city"   placeholder="Ort *"/>
                    </template>
                  </div>
                  <div class="columns large-6 medium-12 small-12">
                    <h4>Ansprechpartner</h4>
                    <input type="text" v-model="save.newAnsprech.firstname" placeholder="Vorname *"/>
                    <input type="text" v-model="save.newAnsprech.lastname" placeholder="Nachname *"/>
                    <input type="text" v-model="save.newAnsprech.email" readonly placeholder="E-Mail *"/>
                    <input type="text" v-model="save.newAnsprech.phone" required placeholder="Telefon *"/>
                  </div>
                </div>
                <div class="row">
                  <div class="columns large-12 medium-12 small-12">
                    <label style="margin-bottom: 12px;display: block;"><input type="checkbox"
                                                                              v-model="save.newLocation.stell"/> Ich
                      benötige eine <span @click="showStell = true" class="stellgenehmigung">Stellgenehmigung</span> für
                      öffentliche Flächen.</label>
                  </div>
                </div>
                <div class="row">
                  <div class="columns large-6 medium-12 small-12">
                    <button class="btn" @click="needsExtraAddress=false" :class="(!needsExtraAddress) ? 'active': ''">
                      Rechnungsadresse ist Lieferadresse
                    </button>
                  </div>
                  <div class="columns large-6 medium-12 small-12">
                    <button class="btn" @click="needsExtraAddress=true" :class="(needsExtraAddress) ? 'active': ''">
                      abweichende Rechnungsadresse
                    </button>
                  </div>
                </div>
              </div>

              <div class="box" v-if="emailIsRegistered && emailIsRegisteredComplete">
                <a class="btn btn-green" style="margin-bottom: 0;" :href="'https://kundenportal.meindl-entsorgung.de/?configurationWaste='+save.waste_id+'&configurationContainer='+save.container_id+'&email='+save.newAnsprech.email"
                   target="_blank">Jetzt
                  einloggen
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" v-if="step === 'ansprech'">
          <div class="columns large-6 medium-12 small-12">
            <button class="btn" @click="step='location'">Schritt zurück</button>
          </div>
          <div class="columns large-6 medium-12 small-12">
            <template
              v-if="save.newAnsprech.firstname && save.newAnsprech.lastname && save.newAnsprech.email && save.newLocation.street && save.newLocation.city && save.newAnsprech.phone">
              <button class="btn" @click="step = 'overview'">Bestätigen</button>
            </template>
            <template v-else>
              <button class="btn btn-disabled" disabled>Bestätigen</button>
            </template>
          </div>
        </div>
        <div class="row flex" v-if="step === 'overview'">
          <div class="columns large-12 medium-12 small-12">
            <div class="singleWrapper">
              <h2>Zusammenfassung</h2>
              <div class="box">
                <div class="row">
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <p><strong
                      v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_name && fullDisplay.container_id && fullDisplay.container_id.title">Container
                      für {{ fullDisplay.waste_id.waste_name }}</strong><br>
                      in
                      <template v-if="$store && $store.state && $store.state.order && $store.state.order.zip">
                        {{ $store.state.order.zip }}
                      </template>
                      {{ this.getSelectedCityByZip }}.<br>
                      {{ fullDisplay.container_id.title }}
                    </p>
                    <table v-if="$store.state.prices">
                      <tbody>
                      <tr>
                        <td>Transportkosten (Container Liefern und wieder abholen)</td>
                        <td style="text-align: right;">{{ priceEuro($store.state.prices.transport) }}</td>
                      </tr>
                      <tr>
                        <td>Miete monatlich(14-Tage Mietfrei)</td>
                        <td style="text-align: right;">{{ priceEuro($store.state.prices.rentMonthly) }}</td>
                      </tr>
                      <tr>
                        <td>Entsorgungskosten pro Tonne</td>
                        <td style="text-align: right;">{{ priceEuro($store.state.prices.pricePerTon) }}</td>
                      </tr>
                      <tr v-for="add in fullDisplay.waste_id.additionals">
                        <td>{{ add.container_name }}</td>
                        <td style="text-align: right;">
                          <template
                            v-if="add.add_price && save.additionals[add.id].amount && save.additionals[add.id].amount > 0 && save.additionals[add.id].id">
                            {{ priceEuro(add.add_price) }} x {{ save.additionals[add.id].amount }} =
                            {{ priceEuro(add.add_price * save.additionals[add.id].amount) }} €
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>Bezahlart</td>
                        <td style="text-align: right;">Kauf auf Rechnung</td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="row">
                      <div class="columns large-6 medium-12 small-12">
                        <h4>Lieferadresse:</h4>
                        <p>{{ save.newLocation.company }}<br>
                          {{ save.newLocation.street }}<br>
                          {{ save.newLocation.zip }} {{ save.newLocation.city }}<br><br>
                        </p>
                        <h4>Ansprechpartner</h4>
                        <p>{{ save.newAnsprech.firstname }} {{ save.newAnsprech.lastname }}<br>
                          {{ save.newAnsprech.email }}<br>
                          {{ save.newAnsprech.phone }}
                        </p>
                      </div>
                      <div class="columns large-6 medium-12 small-12">
                        <h4>Rechnungsadresse</h4>
                        <p v-if="needsExtraAddress">{{ save.newRechnung.company }}<br>
                          {{ save.newRechnung.firstname }} {{ save.newRechnung.lastname }}<br>
                          {{ save.newRechnung.street }}<br>
                          {{ save.newRechnung.zip }} {{ save.newRechnung.city }}<br><br>
                          {{ save.newRechnung.email }}<br>
                        </p>
                        <p v-else>
                          {{ save.newLocation.company }}<br>
                          {{ save.newLocation.firstname }} {{ save.newLocation.lastname }}<br>
                          {{ save.newLocation.street }}<br>
                          {{ save.newLocation.zip }} {{ save.newLocation.city }}<br>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="columns large-12 medium-12 small-12">
                        <!-- back button -->
                        <button class="btn" @click="step='ansprech'">Schritt zurück</button>
                      </div>
                    </div>
                  </div>
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <div class="finishSide">
                      <VueDatePicker
                        model-type="yyyy-MM-dd" format="dd.MM.yyyy" :auto-apply="true" :close-on-auto-apply="true"
                        locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true"
                        v-model="save.lieferdatum" placeholder="gewünschtes Lieferdatum"
                      />
                      <div class="checkbox">
                        <input type="checkbox" v-model="save.abholdatum_check" id="abholdatum_check"/><label
                        for="abholdatum_check">zusätzliche Abholung beauftragen</label>
                      </div>
                      <VueDatePicker v-if="save.abholdatum_check"
                                     model-type="yyyy-MM-dd" format="dd.MM.yyyy" :auto-apply="true"
                                     :close-on-auto-apply="true"
                                     locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen"
                                     :teleport="true"
                                     v-model="save.abholdatum" placeholder="gewünschtes Abholdatum"
                      />
                      <textarea v-model="save.stellplatzbeschreibung" placeholder="exakte Stellplatzbeschreibung"
                                cols="30" rows="3"></textarea>
                      <textarea v-model="save.kommentar" placeholder="Kommentar" cols="30" rows="3"></textarea>
                      <div class="checkbox"><input type="checkbox" v-model="save.privacy" id="privacy" required/><label
                        for="privacy">Ich habe die <a href="https://kundenportal.meindl-entsorgung.de/datenschutz"
                                                      target="_blank">Datenschutzerklärung</a> und die <a
                        href="https://kundenportal.meindl-entsorgung.de/agb" target="_blank">AGB</a> gelesen und
                        bestätige diese.</label></div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="save.confirmSorten" id="sorteninfo" required/><label
                        for="sorteninfo">Ich habe die <span @click="showCombinedDescs = true" class="stellgenehmigung">Sorteninformationen</span>
                        gelesen.</label></div>
                      <div class="checkbox" v-if="!save.newLocation.stell">
                        <input type="checkbox" v-model="save.stellgenehmigung" id="stellgenehmigung" required/> <label
                        for="stellgenehmigung">Der Container steht nicht auf einer öffentlichen Fläche.
                        Es wird keine <span @click="showStell = true" class="stellgenehmigung">Stellgenehmigung</span>
                        für öffentliche Flächen benötigt
                      </label></div>

                      <div class="checkbox">
                        <input type="checkbox" v-model="save.globalAccept" id="globalAccept" required/> <label
                        for="globalAccept">Die Gesamtkosten werden Ihnen nach Abzug des Containers in Rechnung gestellt.<br>
                        Preise vorbehaltlich Sortenänderung nach Sortenprüfung.<br>
                        Alle Preise inkl. gesetzlicher MwSt.<br><br>* Bitte beachten Sie die Mindestlastvorgaben von
                        {{ fullDisplay.waste_id.waste_amount }}kg.
                      </label>
                      </div>
                      <template
                        v-if="save.privacy && save.confirmSorten && (save.newLocation.stell || save.stellgenehmigung) && save.globalAccept">
                        <button class="btn" @click="submitAll" :disabled="isSubmitting">Bestätigen</button>
                      </template>
                      <template v-else>
                        <button class="btn btn-disabled" disabled>Bestätigen</button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="showWasteDesc">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" v-if="wasteDescOb.waste_name">{{ wasteDescOb.waste_name }}</h5>
              <h5 class="modal-title" v-else>{{ wasteDescOb.container_name }}</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showWasteDesc = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="columns large-12 medium-12 small-12">
                  <div v-html="wasteDescOb.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showCombinedDescs">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sorteninformationen</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showCombinedDescs = false">x
              </button>
            </div>
            <div class="modal-body" v-if="fullDisplay">
              <div class="row">
                <div class="columns large-12 medium-12 small-12"
                     v-if="fullDisplay.waste_id && fullDisplay.waste_id.description">
                  <div v-html="fullDisplay.waste_id.description"></div>
                </div>
              </div>
              <div class="row">
                <div class="columns large-12 medium-12 small-12"
                     v-if="fullDisplay.container_id && fullDisplay.container_id.description">
                  <div v-html="fullDisplay.container_id.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showStell">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Information Stellgenehmigung</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showStell = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row" v-if="$store && $store.state && $store.state.prices">
                <div class="columns large-12 medium-12 small-12">
                  <div v-html="$store.state.prices.stellgenehmigung"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </main>
</template>
<script>
import axios from "axios";
import jQuery from "jquery";
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  name: "Type",
  data: function () {
    return {
      filter: {
        searchWasteType: '',
        filterActive: false,
        searchContainer: '',
        locationSearch: '',
      },
      step: 'waste',
      save: {
        nacherfassung: false,
        category_id: false,
        waste_id: false,
        container_id: false,
        genehmigung: false,
        location_id: false,
        ansprech_id: false,
        besteller_id: false,
        direktTransport: false,
        fremdkz: '',
        newLocation: {
          company: '',
          street: '',
          zip: this.$store.state.order.zip,
          city: '',
          stell: 0
        },
        newRechnung: {
          company: '',
          firstname: '',
          lastname: '',
          street: '',
          zip: '',
          city: '',
        },
        newAnsprech: {
          firstname: '',
          lastname: '',
          email: '',
          phone: ''
        },
        newAnsprechs: [],
        confirmSorten: false,
        lieferdatum: '',
        abholdatum_check: false,
        abholdatum: '',
        stellplatzbeschreibung: '',
        amount: '',
        kommentar: '',
        additionals: {},
        privacy: false,
        stellgenehmigung: false,
        globalAccept: false
      },
      needsExtraAddress: false,
      showThankYou: false,
      nacherfassung: {
        possible: false,
        done: false,
        email: false,
        phone: false,
        data: {
          id: '',
          email: '',
          phone: ''
        }
      },
      showWaste: false,
      showWasteDesc: false,
      showModalLogin: false,
      showCombinedDescs: false,
      showStell: false,
      wasteDescOb: {},
      categories: [],
      allContainers: [],
      login: {
        username: '',
        password: '',
      },
      fullDisplay: {
        waste_id: {},
        container_id: {},
        container_counter: 'no',
        location_id: {},
        ansprech_id: {},
        zip: ''
      },
      su: true,
      isLoggedIn: false,
      emailIsRegistered: false,
      emailIsRegisteredComplete: false,
      isSubmitting: false,
      errorMessage: ''
    }
  },
  components: {
    VueDatePicker
  },
  computed: {
    getSelectedCityByZip() {
      const zip = this.$store.state.order.zip;
      let city = '';
      switch (zip) {
        case "93138":
          city = "Lappersdorf";
          break;
        case "93092":
          city = "Barbing";
          break;
        case "93093":
          city = "Donaustauf";
          break;
        case "93182":
          city = "Duggendorf";
          break;
        case "93183":
          city = "Kallmünz";
          break;
        case "93096":
          city = "Köfering";
          break;
        case "93073":
          city = "Neutraubling";
          break;
        case "93152":
          city = "Nittendorf";
          break;
        case "93083":
          city = "Obertraubling";
          break;
        case "93080":
          city = "Pentling";
          break;
        case "93186":
          city = "Pettendorf";
          break;
        case "93188":
          city = "Pielenhofen";
          break;
        case "93128":
          city = "Regenstauf";
          break;
        case "93161":
          city = "Sinzing";
          break;
        case "93105":
          city = "Tegernheim";
          break;
        case "93173":
          city = "Wenzenbach";
          break;
        case "93195":
          city = "Wolfsegg";
          break;
        case "93197":
          city = "Zeitlarn";
          break;
        case "93055":
        case "93051":
        case "93049":
        case "93047":
        case "93057":
        case "93053":
        case "93059":
          city = "Regensburg";
          break;
        case "93077":
          city = "Bad Abbach";
          break;
        case "93087":
          city = "Alteglofsheim";
          break;
        case "93177":
          city = "Altenthann";
          break;
        case "93089":
          city = "Aufhausen";
          break;
        case "93090":
          city = "Bach an der Donau";
          break;
        case "93176":
          city = "Beratzhausen";
          break;
        case "93170":
          city = "Bernhardswald";
          break;
        case "93179":
          city = "Brennberg";
          break;
        case "93164":
          city = "Brunn";
          break;
        case "93180":
          city = "Deuerling";
          break;
        case "93095":
          city = "Hagelstadt";
          break;
        case "93155":
          city = "Hemau";
          break;
        case "93098":
          city = "Mintraching";
          break;
        case "93099":
          city = "Mötzing";
          break;
        case "93101":
        case "93102":
          city = "Pfatter";
          break;
        case "93104":
          city = "Sünching";
          break;
        case "84069":
          city = "Schierling";
          break;
        case "93107":
          city = "Thalmassing";
          break;
        case "93109":
          city = "Wiesent";
          break;
        case "93086":
          city = "Wörth an der Donau";
          break;
        case "93133":
          city = "Burglengenfeld";
          break;
        case "93345":
          city = "Hausen";
          break;
        case "93346":
          city = "Ihrlerstein";
          break;
        case "93309":
          city = "Kelheim";
          break;
        case "84085":
          city = "Langquaid";
          break;
        case "93142":
          city = "Maxhütte-Haidhof";
          break;
        case "93351":
          city = "Painten";
          break;
        case "93342":
          city = "Saal an der Donau";
          break;
        case "93158":
          city = "Teublitz";
          break;
        case "93356":
          city = "Teugn";
          break;
        case "93192":
          city = "Wald";
          break;
        default:
          city = "Regensburg"; // Fallback for unmatched ZIPs
      }

      this.$store.state.order.city = city;
      return city;
    }
  },
  methods: {
    checkValidEmail() {
      if (this.checkStringIsValidEmail(this.save.newAnsprech.email)) {
        axios.get(this.$store.state.apiUrl + "config?type=ansprech&email=" + this.save.newAnsprech.email,).then((r) => {
           if(r.data.result && r.data.result.userId){
            this.emailIsRegistered = true;
            this.emailIsRegisteredComplete = true;
           } else {
            this.emailIsRegistered = false;
            this.emailIsRegisteredComplete = true;
           }

        }).catch((e) => {


          console.log(e);
        });
      }
    },
    selectItem(id, type, model) {
      if (type === 'ansprech_id') {
        if (model.AdrTelefon_AdrTelId !== undefined) {
          this.nacherfassung.data.id = model.AdrTelefon_AdrTelId;
          this.nacherfassung.name = model.AdrTelefon_Ansprech;
          var falseIt = 0;
          if (model.AdrTelefon_Telefon === undefined || model.AdrTelefon_Telefon === '' || Array.isArray(model.AdrTelefon_Telefon) || !this.checkStringHaveNumbers(model.AdrTelefon_Telefon)) {
            this.nacherfassung.possible = true;
            this.nacherfassung.phone = true;
          } else {
            falseIt++;
          }
          if (model.AdrTelefon_Email === undefined || model.AdrTelefon_Email === '' || Array.isArray(model.AdrTelefon_Email) || !this.checkStringIsValidEmail(model.AdrTelefon_Email)) {
            this.nacherfassung.possible = true;
            this.nacherfassung.email = true;
          } else {
            falseIt++;
          }
          if (falseIt === 2) {
            this.nacherfassung.possible = false;
          }
        }
      }
      if (type === 'category_id') {
        this.save.waste_id = false;
        this.save.container_id = false;
      }
      if (type === 'location_id') {
        var toChangeLocation = this.locations[id];
        var prefill = {
          conwin_name1: toChangeLocation.conwin_name1,
          conwin_name2: toChangeLocation.conwin_name2,
          conwin_name3: toChangeLocation.conwin_name3,
          conwin_bez: toChangeLocation.conwin_bez,
          conwin_strasse: toChangeLocation.conwin_strasse,
          conwin_hausnr: toChangeLocation.conwin_hausnr,
          conwin_plz: toChangeLocation.conwin_plz,
          conwin_ort: toChangeLocation.conwin_ort,
        }
        this.save.fremdkz = this.locations[id].fremdkz;
        this.locationChanges[id] = prefill;
      }
      if (this.save[type] === id) {
        this.save[type] = false;
      } else {
        this.save[type] = id;
      }
      if (model) {
        this.fullDisplay[type] = model
      }
    },
    checkStringIsValidEmail(string) {
      return /\S+@\S+\.\S+/.test(string);
    },
    checkStringHaveNumbers(string) {
      // replace empty spaces
      string = string.replace(/\s/g, '');
      return /\d/.test(string);
    },
    getCategories() {
      axios.get(this.$store.state.apiUrl + "config?type=binvollSorts",).then((r) => {
        this.categories = r.data.result.data;
      }).catch((e) => {
        console.log(e);
      });
    },
    priceEuro(value) {
      return Number(value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
    },
    checkItems(category) {
      let a;
      for (a in category.items) {
        if (category.items[a].filterMatch === 1) {
          return true;
        }
      }
      return false;
    },
    setAdditionals() {
      let VueInner = this;
      this.save.additionals = {};
      let a;
      for (a in this.fullDisplay.waste_id.additionals) {
        this.save.additionals[this.fullDisplay.waste_id.additionals[a].id] = {
          id: null,
          amount: ''
        };
      }
      axios.get(this.$store.state.apiUrl + "config?type=configuratorZip&waste_id=" + this.save.waste_id + "&container_id=" + this.save.container_id + "&zip=" + this.$store.state.order.zip,).then((r) => {
        VueInner.$store.state.prices = r.data.result.data;
        console.log(r.data.result.data);
      }).catch((e) => {
        console.log(e);
      });
    },
    showSortenInfoDirect(waste) {
      if (waste) {
        this.wasteDescOb = waste;
        this.showWasteDesc = true;
      }
      // show in confirm popup
    },
    showCombinedInfos() {
    },
    submitAll() {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;
      let VueInner = this;
      let postContent = {
        data: this.save,
      }
      axios.post(this.$store.state.apiUrl + "configurator", postContent).then((r) => {

        this.showThankYou = true;
      }).catch((e) => {
        console.log(e);
        this.errorMessage = e.response.data.todo.content;
        console.log(e);
      });
    },
  },
  // set progress to 10
  created() {
    this.getCategories();
    if (this.$store.state.order.zip === '') {
      this.$router.push('/');
    }
  },
  watch: {
    '$data.filter.locationSearch': function () {
      let lengthFilter = this.filter.locationSearch.length;
      if (lengthFilter > 0) {
        this.filter.filterActive = true
      } else {
        this.filter.filterActive = false;
      }
      let index;
      let key;
      let search;
      let string;
      for (key in this.locations) {
        for (index in this.locations[key]) {
          if (this.filter.locationSearch.length <= 0) {
            this.locations[key].filterMatch = 1;
          } else {
            this.locations[key].filterMatch = 0;
            if (this.filter.locationSearch.length > 0) {
              search = this.filter.locationSearch.toLowerCase();
              string = this.locations[key].address.toLowerCase() + this.locations[key].conwin_ort.toLowerCase() + this.locations[key].conwin_bez.toLowerCase();
              if (string.indexOf(search) >= 0) {
                this.$set(this.$data.locations[key], "filterMatch", 1);
              }
            }
          }
        }
      }
    },
    '$data.filter.searchWasteType': function () {
      let lengthFilter = this.filter.searchWasteType.length;
      if (lengthFilter > 0) {
        this.filter.filterActive = true
      } else {
        this.filter.filterActive = false;
      }
      let index;
      let key;
      let search;
      let string;
      let tagString;
      for (key in this.categories) {
        for (index in this.categories[key].items) {
          if (this.filter.searchWasteType.length <= 0) {
            this.categories[key].items[index].filterMatch = 1;
          } else {
            this.categories[key].items[index].filterMatch = 0;
            if (this.filter.searchWasteType.length > 0) {
              search = this.filter.searchWasteType.toLowerCase();
              string = this.categories[key].items[index].waste_name.toLowerCase();
              tagString = this.categories[key].items[index].tags.toLowerCase();
              if (string.indexOf(search) >= 0 || tagString.indexOf(search) >= 0) {
                this.categories[key].items[index].filterMatch = 1;
              }
            }
          }
        }
      }
      // if (this.su) {
      setTimeout(function () {
        let catAmount = jQuery(".binvollSelect.typeCategory").length;
        if (catAmount === 1) {
          if (!jQuery(".binvollSelect.typeCategory").hasClass("active")) {
            jQuery(".binvollSelect.typeCategory").trigger("click");
          }
        }
      }, 200);
      // }
    },
    'this.$store.state.order.zip': {
      handler(zip) {
        const city = this.getSelectedCityByZip;
        this.save.newLocation.city = city;
        this.$store.state.order.city = city;
      },
      immediate: true // This will trigger the watcher immediately on component mount
    }
  }
}
</script>
<style scoped>
</style>
